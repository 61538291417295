import axios from 'axios';
import autharizedHeaders from './lib/autharizedHeaders';
import _ from 'lodash';
import localStorageSafe from "../lib/localStorageSafe";
const transactionAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API });
const serviceAPI = axios.create({baseURL: process.env.REACT_APP_DATA_API});
const dataAPIAxios = axios.create({ baseURL: process.env.REACT_APP_DATA_API });
const dataAdminAxios = axios.create({ baseURL: process.env.REACT_APP_ADMIN_API });
const nodeAPIAxios = axios.create({ baseURL: process.env.REACT_APP_AUTH_API });

// export const getTransaction = async (payload) =>{
//     console.log(payload)
//     // const {transactionSerialId} = payload;
//     // console.log('transactionSerialId',transactionSerialId);
//     return await transactionAPI.post('/ordersSelectByTransaction',{payload},{headers: autharizedHeaders()}).then(res =>({item:res.data.orders[0].transaction}))
// }
export const getTransaction = async (payload) =>{
    console.log('getTransaction',payload)
    // const {transactionSerialId} = payload;
    // console.log('transactionSerialId',transactionSerialId);
    return await transactionAPI.get('/dish/transaction/myTransaction',{headers: autharizedHeaders(),params:payload}).then(res =>({item:res.data.transactions[0]}))
}
export const getTransactions= async (payload) =>{
    console.log('getTransaction',payload)
    return await transactionAPI.get('/dish/transaction/',{headers: autharizedHeaders(),params:payload}).then(res =>({items:res.data.transactions}))
}
export const getOrderOne = async (payload) => {
    console.log(payload);
    return await transactionAPI.get('/V2/orderOne',{headers:autharizedHeaders(),params:payload}).then(res=>{
        const {order={}} = res.data;
        const getItemStrs = order.service?(_.pickBy(_.split(order.service.name, '\n'), _.identity)):['',''];
        const getItem = { ...order.service, title: payload.language === 'en' ? getItemStrs[0] : getItemStrs[1] };
        return { item:{...res.data.order, serviceId: getItem.id, item: getItem}};
    })
}
export const getServices = async (payload) => {
    const { params = {} } = payload;
    return await serviceAPI.get('/servicesShowPurchasing',{headers: autharizedHeaders() , params:params}).then(res=>{
        return {items:res.data.services,params}
    })
}

export const getServiceItem = async (payload) => {
    const { params = {} } = payload;
    console.log('payload', payload);
    return await serviceAPI.get('/serviceShow',{headers: autharizedHeaders() , params:params}).then(res=>{
        return {item:res.data,params}
    })
}

// export const postItemService = async (payload) => {
//     console.log(payload)
//     return await serviceAPI.post('/dish/transaction/restTransactionOne',payload,{headers: autharizedHeaders()}).then(res => ({
//         items:res.data.transaction
//     }))
// }
export const getTransportations = async(payload)=>{
    console.log('getTransportations',payload);
    const { params = {} } = payload;
    let _params = { sort:'id,asc', size:1000,page:0,...params};
    return await serviceAPI.get('/transportation/',{headers:autharizedHeaders(),params:_params}).then(res=>{
        return{items:res.data.transportations}
    })
}
export const postItemService = async (payload) => {
    console.log(payload)
    const fetched =await serviceAPI.post('/dish/transaction/restTransactionOne',payload,{headers: autharizedHeaders()}).then(res => ({
        items:res.data.transaction,
        status:res.status
    }))
    if (fetched.status !== 200){
        throw new Error('Transaction Create error,Please Try Again or Contact Us');
    } else {
        return fetched;
    }
}

//transactionCaculatePrice
export const postCheckoutService = async (payload) => {
    console.log('postCheckoutService',payload)
    return await serviceAPI.post('/dish/transaction/caculatePrice',payload,{headers: autharizedHeaders()}).then(res => ({
        item:res.data.orderAmount
    }))
}
export const postRefundCheckoutService = async (payload) => {
    console.log('postRefundCheckoutService',payload)
    return await serviceAPI.post('/transaction/refund/',payload,{headers: autharizedHeaders()}).then(res => ({
        item:res.data.refund
    }))
}
export const LineProduct = ({ quantity, item = {} }) => {
    const lineTotal = !item.price ? 0 : item.price * quantity;
    return { id: item.id, quantity, item, lineTotal };
};

export const ordersShowMine = async(payload) => {
    const { params = {} } = payload;
    console.log('orderShowMine', payload)
    return await serviceAPI.get('/ordersShowMine',{headers: autharizedHeaders(),params:params}).then(res=>{
        return {items:_.map(res.data.orders,order=>({...order,lineItem:LineProduct({quantity:order.quantity,item:order.service})}))}
    })
}

export const updateTransactions = async(params) => {
    console.log('updateTransactions', params)
    const {transactionIds,payload} = params;
    const deliveryStatus = {deliveryStatus:payload};
    console.log('updateTransactions', deliveryStatus);
    return await transactionAPI.put(`/dish/transaction/restTransaction/${transactionIds}`,{restTransaction:deliveryStatus},{headers:autharizedHeaders()}).then(res=>({
        item:res.data.restTransaction
    }))
}

export const updateTransaction = async({restTransaction,serialId}) => {
    console.log('updateTransactions', restTransaction,serialId)
    // const {serialId,restTransaction} = payload;
    return await transactionAPI.put(`/dish/transaction/restTransaction/${serialId}`,{restTransaction:restTransaction},{headers:autharizedHeaders()}).then(res=>({
        item:res.data.restTransaction
    }))
}