import React, { createContext, useReducer, useCallback, useEffect,useState } from 'react';
import { alipayFormAPI,postStripe,postWechat } from '../../API/payment.api';
import { dispatchAction, setStateAction } from '../lib';
import {transactionRoute} from '../../map/map'
import _ from 'lodash';

const Context = createContext({});

const reducer = (state, action = {}) => {
	const { actionType, payload = {}, condition } = action;
	console.log(action);
	switch (actionType) {
		case 'getItem':
			return { ...state, ...payload, condition };
		case 'loadItems':
			return { ...state, ...payload, items: [ ...state.items, ...payload.items ], condition };
		case 'getCodeUrl':
			return {...state, ...payload};
		default:
			return { ...state, condition };
	}
};
// "The transaction has been paid or expired. Check the transction for details."

const resultHandler = ({actionType,payload,error}) => {
    switch (actionType){
		case 'postStripe':
			if (payload && payload.status === 'SUCCESS') {
				window.alertG('Success', { name: 'postStripe', text: 'Success', icon: 'check' })
				window.pushG(`${transactionRoute.history.url}`);
			}else if(error){
				console.log(error)
				window.pushError({title:'Payment Fail',message:_.includes(error.message,'Your card was declined')?'Your card was declined, Please try again':_.includes(error.message,'The transaction has been paid or expired')?'The transaction has been paid or expired':'Payment Fail, Please try again'})
			}
			break;
        default:
            break;
    }
}
const initItemsState = { badgePostIds: [], items: [], condition: { error: {} }, params: {} };
const initItemState = { item: {}, condition: { error: {} }, params: {} };
const Provider = props => {
	const [ state, dispatch ] = useReducer(reducer, {});
	const [ error, setError ] = useState({});

	const getAlipayForm = useCallback(() => dispatchAction(dispatch, alipayFormAPI, 'getInnerHtml'));
    const [stripeState, dispatchStripe] = useReducer(reducer, initItemState);
	const postStripeAction = useCallback(dispatchAction(dispatchStripe,postStripe,'postStripe'),[]);
	const [wechatState,dispatchWechat] = useReducer(reducer,initItemState);
	const postWechatAction = useCallback(dispatchAction(dispatchWechat,postWechat,'getCodeUrl'));

	useEffect(
		() => {
			if (error.message) {
				const { message, name } = error;
				window.alertG(message, { name, text: message, icon: 'times' });
			}
		},
		[ error ]
	);
	const _onLoad = useCallback(action => {}, []);
	const _onChange = useCallback((e, action = {}) => {
		// console.log(data.name, data.value);
		const { actionType } = action;
		switch (actionType) {
			case 'getAlipayForm':
				getAlipayForm();
				break;
			case 'postStripe':
				if (action.value.error) {
					setError({ ...action.value.error, name: action.value.error.type });
				} else if (action.value.token) {
					postStripeAction(action.value).then((payload)=>resultHandler({actionType,payload})).catch(error => resultHandler({actionType,error}))
				}
				break;
			case 'postWechat':
				console.log('action.payload',action.payload);
				postWechatAction(action.payload)
			default:
				console.log(action);
				console.log('not set');
				break;
		}
	}, []);
	useEffect(() => {
		return () => {};
	}, []);
	return (
		<Context.Provider
			value={{
				state,
				stripeState,
				wechatState,
				onLoad: _onLoad,
				onChange: _onChange
			}}>
			{props.children}
		</Context.Provider>
	);
};

export const withPayment = Componet => props => {
	return (
		<Provider>
			<Componet {...props} />
		</Provider>
	);
};
export default Context;
