import { commaStringToObjList, objListToCommaString } from '../../lib/commaParser';
const RestaurantInFormat = (restaurant={}) => {
    if(restaurant.shopType){
        return { ...restaurant,title:restaurant.name,formattedAddress:restaurant.address,postId:restaurant.shopType.postId,openHours:commaStringToObjList(restaurant.openHours,2, [ 'day', 'hour' ])}
    }else{
        return { ...restaurant,title:restaurant.name,formattedAddress:restaurant.address,openHours:commaStringToObjList(restaurant.openHours,2, [ 'day', 'hour' ])}
    }
}
const RestaurantOutFormat = (restaurant={}) => {
    return {...restaurant, name:restaurant.title,address:restaurant.formattedAddress,openHours:objListToCommaString(restaurant.openHours)}
}
const RestaurantFormat = ()=>{}

RestaurantFormat.In = RestaurantInFormat;
RestaurantFormat.Out = RestaurantOutFormat;
export default RestaurantFormat;