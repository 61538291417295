import React, { useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import parseQuery from '../../lib/parseQuery';
import LanguageContext, { withLanguage } from './LanguageContext';
import LayoutContext, { withLayout } from './LayoutContext';
import { withRouter } from 'react-router-dom';

const GlobalContext = React.createContext({});

const GlobalProviderP = props => {
	// console.log(props);
	const { history } = props;
	const { history: { push, replace, goBack } } = props;
	const [ error, setError ] = useState({});
	const [ role, setRole ] = useState('tenant');
	const languageContext = useContext(LanguageContext);
	const layoutContext = useContext(LayoutContext);
	const [ query, setQuery ] = useState({});
	const [ searchHistory, setSearchHistory ] = useState(localStorage.getItem('searchHistory') ? JSON.parse(localStorage.getItem('searchHistory')) : []);
	const [ state, setState ] = useState({ ...languageContext, ...layoutContext });
	const [ agent ] = useState(_.includes(navigator.userAgent, 'Mobile') ? 'Mobile' : 'Desktop');
	const [ isOnline ] = useState(true);
	const [ alertState, setAlertObj ] = useState({});
	const alertG = useCallback((message, data) => {
		setAlertObj({ message, ...data });
	}, []);
	const pushError = useCallback(data => {
		setError(data);
	}, []);
	useEffect(() => {
		window.alertG = alertG;
		window.pushG = push;
		window.replaceG = replace;
		window.goBackG = goBack;
		window.pushError = pushError;
	}, []);

	const { location } = props;
	const setSearch = useCallback(
		(e, data) => {
			if (!_.find(searchHistory, [ 'text', data.value.text ])) {
				const searchHistoryTemp = [ data.value, ...searchHistory ];
				while (searchHistoryTemp.length > 5) {
					searchHistoryTemp.pop();
				}
				setSearchHistory(searchHistoryTemp);
				localStorage.setItem('searchHistory', JSON.stringify(searchHistoryTemp));
			}
		},
		[ searchHistory ]
	);

	useEffect(
		() => {
			if (location) {
				try {
					const query = parseQuery.parse(location.search);
					setQuery(query);
				} catch (err) {
					console.log(err);
				}
			}
		},
		[ location ]
	);

	useEffect(
		() => {
			setState(state => ({ ...state, ...languageContext }));
		},
		[ languageContext ]
	);
	useEffect(
		() => {
			setState(state => ({ ...state, ...layoutContext }));
		},
		[ layoutContext ]
	);

	return <GlobalContext.Provider value={{ ...state, error, pushError, history, isOnline, query, agent, searchHistory, setSearch, role, setRole, alertG, alertState, setAlertObj }}>{props.children}</GlobalContext.Provider>;
};

export const GlobalProvider = withRouter(withLayout(withLanguage(GlobalProviderP)));

export const withGlobal = Component => props => {
	return (
		<GlobalProviderP>
			<Component {...props} />
		</GlobalProviderP>
	);
};
export const defaultHeaderConfig = {
	title: process.env.REACT_APP_TITLE,
	imageUrl: 'https://i.imgur.com/HpF6vhu.jpg',
	description:process.env.REACT_APP_DESCRIPTION,
	author: 'RoRo Inc',
	type: 'website',
	url: 'https://www.rorohome.com'
};
export default GlobalContext;
