import React, {createContext, useCallback, useEffect, useReducer, useState} from 'react';
import _ from 'lodash';
import {dispatchAction} from '../lib';
import {
    getProducts,
    getProduct,
    getProductsWithCategory,
    postProduct,
    getMoreProducts,
    getProductLabels,
    getProductLabel,
    getLabeledProducts,
    postProductLabel,
    putProductLabel,
    putProduct,
    putQuickProductLabel,
    putProductQuick,
    getUploadedFiles,
    productUploadImages,
    putProductImages,
    putProductImage,
    getParentLabels
} from '../../API/product.api';
import {productRoute, shopRoute} from "../../map/map";
import {LineProduct} from "../Transaction/TransactionContext2";
import {getItems} from "../redux/hook.redux";
const restaurant = process.env.REACT_APP_RESTAURANT_ID;

const Context = createContext({});

const reducer = ( state,action={} )=>{
    const {actionType,payload={}, condition} = action;
    state.condition = condition;
    console.log('postItem reducer',payload);
    switch(actionType){
        case 'getItems':
        case 'getItem':
            return {...state,...payload};
        case 'getMoreItems':
            return {...state,items:[...state.items,...payload.items]};
        default:
            return {...state};
    }
};

const initItemsState = { items:[], categorys:[],condition:{ error:{} }, params:{} };
const initItemState = { item:{}, condition:{ error:{} }, params:{} };

export const Provider = props => {
    // itemsState
    const [itemsState,dispatchItems] = useReducer(reducer,{...initItemsState});
    const [labelState,dispatchLabel] = useReducer(reducer,{...initItemsState});
    const getItemsAction = useCallback(dispatchAction(dispatchItems,getProducts,'getItems'));
    const getLabelItemsAction = useCallback(dispatchAction(dispatchItems,getLabeledProducts,'getItems'));
    const getMoreItemsAction = useCallback(dispatchAction(dispatchItems,getMoreProducts,'getMoreItems'));
    const getFileItemsAction = useCallback(dispatchAction(dispatchItems,getUploadedFiles,'getItems'));
    //ItemState
    const [itemState,dispatchItem] = useReducer(reducer,{...initItemState});
    const {items:labels} = labelState;
    const {categorys} = itemsState;
    let selectItemList = [];
    const [idtest,setIdtest] = useState(0); //xinwang edit 20210128
    const [nametest,setNametest] = useState(null);   //xinwang edit 20210128
    const [isSelected,setSelected] = useState(false);
    const getItemAction = useCallback(dispatchAction(dispatchItem,getProduct,'getItem'));
    const postLabelAction = useCallback(dispatchAction(dispatchLabel,postProductLabel,'getItem'));
    const getLabelsAction = useCallback(dispatchAction(dispatchLabel,getProductLabels,'getItems'));
    const getLabelAction = useCallback(dispatchAction(dispatchItem,getProductLabel,'getItem'));
    const getParentLabelAction = useCallback(dispatchAction(dispatchLabel,getParentLabels,'getItems'));
    const putLabelAction = useCallback(dispatchAction(dispatchLabel,putProductLabel,'getItem'));
  //  const putQuickLabelAction = useCallback(dispatchAction(dispatchLabel,putQuickProductLabel,'getItem')); xinwang edit 20210122
    const putQuickLabelAction = useCallback(dispatchAction(dispatchItem,putQuickProductLabel,'getItem')); // xinwang edit 20210122
    const postItemAction = useCallback(dispatchAction(dispatchItem,postProduct,'getItem'));
    const putQuickItemAction = useCallback(dispatchAction(dispatchItem,putProductQuick,'getItem')); //xinwang edit 20210122
    const putItemAction = useCallback(dispatchAction(dispatchItem,putProduct,'getItem')); //xinwang edit 20210122
    const postImageAction = useCallback(dispatchAction(dispatchItems,productUploadImages,'getItems'));
    const putImageAction = useCallback(dispatchAction(dispatchItem,putProductImage,'getItem'));
    const [ isServiceOpen, setIsServiceOpen ] = useState(false);
    const [ isPreviewOpen, setIsPreviewOpen ] = useState(false);
    const [imagePreview, setImagePreview] = useState({});
    const [filter,setFilter] = useState({category:'',minPrice:0,maxPrice:100000000000,status: 'All',label:'', availabilityStatus:'All' }); //xinwang edit 20210208
    const [sort,setSort] = useState('all');
    const [method,setMethod] = useState('all');
    
    // xinwang edit text 20210129
    const labelNavOptions = [
        ..._.times(labels.length, n=>({ key: n + '', text:labels[n].cnName + ' -- 等级: ' + labels[n].level + ' -- ID: '+ labels[n].id, value: {id:labels[n].id,level:labels[n].level,title:labels[n].title} }))
    ] 

    
    const labelFilterOptions = [
        {key:'0',text:'Any',value:{id:1}},
        ..._.times(labels.length, n=>({ key: n + 1 + '', text:labels[n].cnName, value: {id:labels[n].id,level:labels[n].level,title:labels[n].title} }))
    ]

   
    const categoryNavOptions = [
        { key: '0', text: 'Any', value: '' },
        ..._.times(categorys.length, n=>({ key: n + 1 + '', text:categorys[n], value: categorys[n]}))
    ]
    const handleServiceModalToggle = useCallback(() => {
        setIsServiceOpen(value => !value);
    }, []);
    const handlePreviewServiceModalToggle = useCallback(() => {
        setIsPreviewOpen(value => !value);
    }, []);


    const _onLoad = useCallback( data => {
        console.log('onload',data);
        const {query} = data;
        const {category} = query;
        if(/product/g.test(data.pathname)) {
            if (/label/g.test(data.pathname)) {
                getLabelsAction({params: {}});
                // } else if (/create/g.test(data.pathname)) {
                //     getLabelsAction({params: {}})
                //     getItemsAction({params: {}})
                // }
            } else if (/\/file/g.test(data.pathname)) {
                console.log('file onload', data);
                getFileItemsAction({params: {}});
            }else if(/\/edit/g.test(data.pathname)){
                const regex = /\d+/g;
                const id = data.pathname.match(regex).toString();
                getItemAction({params:{id}})
            }else{
                getLabelsAction({params:{}})               
                //getItemsAction({params:{}}) xinwang edit 20210203
            }
        }
    },[dispatchItems,dispatchLabel,dispatchItem]);
    const _onChange = useCallback((e,action) =>{
        const {actionType, payload={}, select={}} = action;
        console.log('action',action);
        switch(actionType){
            case 'setSelect':
                const {id} = payload;
                if(selectItemList.includes(id)){
                    selectItemList = selectItemList.filter((item)=> item != id.toString());
                }else{
                    selectItemList.push(id);
                }
                if(selectItemList.length > 0){
                    setSelected(true);
                }else{
                    setSelected(false);
                }
                break;
            case 'setImageSelect':
                const {name:imageName} = payload;
                if(selectItemList.includes(imageName)){
                    selectItemList = selectItemList.filter((item)=> item != imageName);
                }else{
                    selectItemList.push(imageName);
                }
                if(selectItemList.length > 0){
                    setSelected(true);
                }else{
                    setSelected(false);
                }
                break;
            case 'imagePreview':
                console.log('imagePreview',payload);
                setImagePreview(payload);
                handlePreviewServiceModalToggle();
                break;
            case 'getItems':
                console.log('getItems',payload);
                const {labelId:labelId} = payload;
                getItemsAction({params:{...payload}}).then(()=>{
                    setIdtest(labelId);    
                    setNametest(payload.name);
                    });
                // xinwang edit 20210128 set filter
                
                break;
            case 'getMoreItems':
                getMoreItemsAction(payload)
                break;
            case 'getPreviewItem':
                window.open(`https://rorohome.com/shop/detail/${payload.id}`,'_blank')
                // handlePreviewServiceModalToggle();
                break;
            case 'getItem':
                getItemAction({params:{...payload}});
                //handleServiceModalToggle();
                break;
            case 'setFilter':
                setFilter({...filter},payload);
                break;
            // case 'postItem':
            //     console.log('postItem',payload);
            //     const {labels,price,availability,isBestDeal,isRecommended,visibility:isVisible,discountedPriceDouble:editDPriceDouble,cost:postCost} = payload;
            //     //const {level} = labels;
            //     let labelIds = [];
            //     c(labels).then((res)=>{
            //     });
            //     labelIds.push(labels.id);
            //     let priceDouble = parseFloat(price);
            //     let restaurantId = parseInt(restaurant);
            //     delete payload.labels;
            //     delete payload.price;
            //     const _payload = {...payload, restaurantId,labelIds,discountedPriceDouble:parseFloat(editDPriceDouble),cost:parseFloat(postCost),priceDouble,availability:availability === 'true',isBestDeal:isBestDeal==='true'
            //         ,isRecommended:isRecommended === 'true',visibility: isVisible=== 'true'};
            //     console.log('postItem',_payload);
            //     postItemAction({...payload,restaurantId,label:labels,priceDouble}).then((data = {})=>{
            //         const { item = {} } = data;
            //         if (item.id) {
            //             getItemAction({params:{id:item.id}}).then((res)=>{
            //                 handleServiceModalToggle();
            //                 window.pushG(`${productRoute.main.url}`);
            //             });
            //         }
            //     });
            //     break;
            // xin wang edit 20210203  delet getParentLabelAction
            case 'postItem':
                console.log('postItem',payload);
                let restaurantId = parseInt(restaurant);

                postItemAction({...payload,restaurantId:restaurantId}).then((data = {})=>{
                    const { item = {} } = data;
                    if (item.id) {
                        getItemAction({params:{id:item.id}}).then((res)=>{
                            handleServiceModalToggle();
                            window.pushG(`${productRoute.main.url}`);
                        });
                    }
                });
                break;
            // case 'editItem':
            //     const {labels:editLabel,price:editPrice,discountedPriceDouble,cost} = payload;
            //     let editLabelIds = [];
            //     let editPriceDouble = parseFloat(editPrice);
            //     let editDiscountedPriceDouble = parseFloat(discountedPriceDouble);
            //     let editRestaurantId = parseInt(restaurant);
            //     let editCost = parseFloat(cost);
            //     delete payload.labels;
            //     delete payload.price;
            //     if(editLabel != undefined){
            //         getParentLabelAction(editLabel).then((res)=>{
            //             if(res){
            //                 editLabelIds = res;
            //                 putItemAction({...payload,restaurantId:editRestaurantId,cost:editCost,labelIds:editLabelIds,discountedPriceDouble:editDiscountedPriceDouble,priceDouble:editPriceDouble}).then((data={})=>{
            //                     const { item = {} } = data;
            //                     if (item.id) {
            //                         getItemAction({params:{id:item.id}}).then((res)=>{
            //                             handleServiceModalToggle();                                      
            //                             window.pushG(`${productRoute.main.url}`);
            //                         });
            //                     }
            //                 })
            //             }
            //         });
            //     }else{
            //         putItemAction({...payload,restaurantId:editRestaurantId,priceDouble:editPriceDouble}).then((data={})=>{
            //             const { item = {} } = data;
            //             if (item.id) {
            //                 window.pushG(`${productRoute.main.url}`);
            //             }
            //         })
            //     }
            //     break;

            // xinwang edit 20210203
            case 'editItem':              
                const {price:editPrice,discountedPriceDouble,cost,labels:editLabel} = payload;
                let editPriceDouble = parseFloat(editPrice);
                let editDiscountedPriceDouble = parseFloat(discountedPriceDouble);
                let editRestaurantId = parseInt(restaurant);
                let editCost = parseFloat(cost);
                delete payload.price;
                delete payload.labels;

                    if(editLabel != undefined){
                        let editLabelIds = [];
                        for(let i in editLabel){                    
                            editLabelIds.push(editLabel[i].id);
                        }

                        if((editLabelIds.indexOf(1) == '-1')&&(editLabelIds.length!=0)){
                            editLabelIds.push(1);

                        }
                        
                    console.log('editLabelIds xinwang 1111111', editLabelIds);
                
                    putItemAction({...payload,restaurantId:editRestaurantId,cost:editCost,labelIds:editLabelIds,discountedPriceDouble:editDiscountedPriceDouble,priceDouble:editPriceDouble}).then((data={})=>{
                        const { item = {} } = data;
                        if (item.id) {
                            getItemAction({params:{id:item.id}}).then((res)=>{
                                //handleServiceModalToggle(); 
                                setIsServiceOpen(true) ;                                    
                                window.pushG(`${productRoute.main.url}`);
                            });
                        }
                    })

                }else{

                    putItemAction({...payload,restaurantId:editRestaurantId,cost:editCost,discountedPriceDouble:editDiscountedPriceDouble,priceDouble:editPriceDouble}).then((data={})=>{
                        const { item = {} } = data;
                        if (item.id) {
                            getItemAction({params:{id:item.id}}).then((res)=>{
                                //handleServiceModalToggle(); 
                                setIsServiceOpen(true) ;                                       
                                window.pushG(`${productRoute.main.url}`);
                            });
                        }
                    })

                }
                
                
                break;
            case 'quickEditItem':
                console.log('quickEditItem',payload);
                putQuickItemAction({...payload}).then(()=>{                    
                        window.pushG(`${productRoute.main.url}`);
                                          
                        //xinwang edit 20210203
                });
                // putQuickItemAction({...payload}).then((data={})=>{
                //     const { item = {} } = data;
                //     if (item.id) {
                //         getItemAction({params:{id:item.id}});                     
                //     }
                                 
                //     });

                break;

            case 'quickEditLabel':
                const {visibility,id:editId} = payload;
                const body = {visibility,id:editId}
                //putQuickLabelAction({...body});   xinwang edit 20210126
                putQuickLabelAction({...body}).then(()=>{
                    getLabelsAction({...payload})
                });
                break;
            case 'postLabel':
                postLabelAction({...payload}).then((data = {})=>{
                    const { item = {} } = data;
                    if (item.id) {
                        window.pushG(`${productRoute.label.url}`);
                    }
                })
                break;
            case 'editLabel':
                putLabelAction({...payload}).then((data = {})=>{
                    const { item = {} } = data;
                    if (item.id) {
                        window.pushG(`${productRoute.label.url}`);
                    }
                })
                break;
            case 'getLabel':
                //handleServiceModalToggle();  xinwang edit 20210208
                getLabelAction({...payload});
                getLabelItemsAction({...payload});
                break;

            case 'postImage':
                console.log('postImage',action);
                postImageAction({...payload}).then((data={})=>{
                getFileItemsAction({params: {}});
                });
                break;
            case 'updateImage':
                console.log('updateImage payload',payload);
                console.log('updateImage selectItemList',selectItemList);
                const {name,lastDays} = payload;
                let days;
                if(lastDays === 0){
                    days = 3;
                }else{
                    days = 0;
                }
                putImageAction({name,days}).then((data={})=>{
                    getFileItemsAction({params: {}});
                });
                break;
            default:
                console.log(action,'not set');
                break;
        }
    },[dispatchItem]);
    console.log('postItem state',itemState);
    console.log('ProductContext',selectItemList);
    return(
        <Context.Provider
            value={{
                itemsState,
                itemState,
                labelState,
                categorys,
                onServiceToggle: handleServiceModalToggle,
                onPreviewToggle:handlePreviewServiceModalToggle,
                isPreviewOpen,
                isServiceOpen,
                onChange:_onChange,
                onLoad:_onLoad,
                selectItemList,
                setFilter,
                filter,
                imagePreview,
                categoryNavOptions,
                labelFilterOptions,
                labelNavOptions,
                isSelected,
                labels,
                idtest,
                nametest,
                sort,
                setSort,
                method,
                setMethod            
            }}
        >
            {props.children}
        </Context.Provider>
    )
}

export const withProduct = Component => props =>{
    return(
        <Provider>
            <Component {...props}/>
        </Provider>
    )
}
export const labelNavOptions = labels => {
    const temp = labels.map((label,index)=>{
        return label;
    })
    return temp;
}

export const categoryNavOptions = categorys => {
    const temp = categorys.map((category,index)=>{
        return category;
    })
    return temp;
}

export const category = {
    name: 'category',
    options: categoryNavOptions
};



export const labels = {
    name: 'labels',
    options: labelNavOptions
};

export default Context;