export const homeRoute = {
	main: {
		url: '/home',
		name: 'home',
		title: 'Home'
	}
};
export const shopRoute = {
	main: {
		url:'/shop',
		path:'/shop',
		name:'shop',
		title:'Shop'
	},
	list:{
		url:'/shop',
		name:'list',
		title:'shop'
	},
	edit:{
		url:'/shop/edit',
		name:'shop',
		title:'Shop/Edit'
	}
}
export const postRoute = {
	main: {
		url: '/post',
		name: 'post',
		title: 'Post',
		path: '/post/:target?/:id?',
		to:'/post'
	},
	detail: {
		url: '/post/detail',
		name: 'detail',
		title: 'Post Detail'
	},
	favourites: {
		url: '/post/favourites',
		name: 'favourites',
		title: 'Favorite Posts'
	},
	edit: {
		url: '/post/edit',
		name: 'edit',
		title: 'Edit Post'
	},
	create: {
		url: '/post/create',
		name: 'create',
		title: 'NewPost'
	},
	mine: {
		url: '/post/mine',
		name: 'mine',
		title: 'My Post'
	},
	mineDetail:{
		url: '/post/mineDetail',
		name:'mineDetail',
		title:'My Post Detail'
	},
	delete: {
		url: '/post/delete',
		name: 'delete',
		title: 'Delete'
	}
};
export const productRoute = {
	main: {
		url: '/product',
		name: 'main',
		title: 'Product',
		path: '/product/:target?/:action?/:id?'
	},
	create:{
		url:'/product/create',
		name:'create',
		title:'Product',
	},
	label:{
		url:'/product/label',
		name:'label',
		title:'Product',
	},
	file:{
		url:'/product/file',
		name:'file',
		title:'Product',
	},
	detail: {
		url: '/product/detail',
		name: 'detail',
		title: 'Detail'
	}
};

export const qrRoute = {
	main: {
		url: '/qrcode',
		name: 'main',
		title: 'QR Code',
		path: '/qrcode'
	}
};
export const searchRoute = {
	main: {
		url: '/search',
		name: 'search',
		title: 'Search Result'
	}
};

export const helpRoute = {
	main: {
		url: '/help',
		name: 'main',
		title: 'Help',
		path: '/help/:target?/:action?'
	},
	housing: {
		url: '/help/housing',
		name: 'Housing',
		title: 'Housing'
	}
};

export const adminTransactionRoute = {
	tenantVerification: {
		url: '/admin/verification/tenantVerification',
		title: 'Tenant Verification',
		name: 'tenantVerification'
	},
	ownerVerification: {
		url: '/admin/verification/ownerVerification',
		title: 'Owner Verification',
		name: 'ownerVerification'
	}
};
export const adminClinetRoute = {
	main: {
		url: '/admin/client',
		title: 'Client',
		name: 'client',
		to: '/admin/client'
	}
};
export const adminPostRoute = {
	crawler: {
		url: '/admin/post/crawler',
		title: 'Crawler',
		name: 'crawler'
	},
	crawlerVanVanpeople: {
		url: '/admin/post/crawler?postType=origin&postLanguage=zh&region=vancouver&provider=vanpeople',
		title: 'Van-Vanpeopele',
		name: 'crawlerVanVanpeople'
	},
	crawlerVanCraigslist: {
		url: '/admin/post/crawler?postType=origin&postLanguage=en&region=vancouver&provider=craigslist',
		title: 'Van-Craigslist',
		name: 'crawlerVanCraigslist'
	},
	crawlerEdmCraigslist: {
		url: '/admin/post/crawler?postType=origin&postLanguage=en&region=edmonton&provider=craigslist',
		title: 'Edm-Craigslist',
		name: 'crawlerEdmCraigslist'
	},
	crawlerEdmKijiji: {
		url: '/admin/post/crawler?postType=origin&postLanguage=en&region=edmonton&provider=kijiji',
		title: 'Edm-Kijiji',
		name: 'crawlerEdmKijiji'
	},

	vanppl: {
		url: '/admin/post/vanppl?postType=origin&postLanguage=zh',
		title: 'Vanpeople Post',
		name: 'vanppl'
	},
	craigslist: {
		url: '/admin/post/craigslist?postType=origin&postLanguage=en',
		title: 'Craglist',
		name: 'craigslist'
	},
	craigslistEdmonton: {
		url: '/admin/post/craigslistEdmonton?postType=origin&postLanguage=en',
		title: 'Craglist Edmonton',
		name: 'craigslistEdmonton'
	},
	createRestaurant: {
		url: '/admin/post/create?postType=restaurant',
		title: 'Create Restaurant Ads',
		name: 'createRestaurant'
	},
	createOrigin: {
		url: '/admin/post/create?postType=origin',
		title: 'Create Post',
		name: 'createOrigin'
	},
	edit: {
		url: '/admin/post/edit',
		title: 'Edit',
		name: 'edit'
	},
	create: {
		url: '/admin/post/create',
		title: 'Create',
		name: 'create'
	},
	main: {
		url: '/admin/post',
		title: 'List',
		name: 'main',
		to: '/admin/post'
	}
};
export const adminArticleRoute = {
	main:{
		url:'/admin/article',
		title: 'List',
		name:'main',
		to:'/admin/article'
	},
	create:{
		url:'/admin/article/create',
		title:'Create',
		name:'create'
	},
	edit:{
		url:'/admin/article/edit',
		title:'Edit',
		name:'edit'
	}
}
export const adminRestaurantRoute = {
	main:{
		url:'/admin/restaurant',
		title:'List',
		name:'main',
		to:'/admin/restaurant'
	},
	create:{
		url:'/admin/restaurant/create',
		title:'Create',
		name:'create'
	},
	edit:{
		url:'/admin/restaurant/edit',
		title:'Edit',
		name:'edit'
	},
	menu:{
		url:'/admin/restaurant/menu',
		title:'Menu',
		name:'menu'
	}
}
export const adminRoute = {
	main: {
		url: '/admin',
		title: 'Admin',
		name: 'main',
		to:'/admin/post'
	},
	post: {
		url: '/admin/post',
		title: 'Post',
		name: 'main'
	},
	article: {
		url: '/admin/article',
		title: 'Article',
		name: 'main'
	},
	restaurant:{
		url:'/admin/restaurant',
		title:'Restaurant',
		name:'main'
	},
	verification: {
		url: '/admin/verification',
		title: 'Verification',
		name: 'verification',
		icon: 'icon-aaIconVerify'
	}
};

export const authRoute = {
	main: {
		url: '/auth',
		title: 'Auth',
		name: 'main',
		path: '/auth/:category?/:target?/:method?',
		to:'/auth'
	},
	fastLogin:{
		url:'/auth/fastLogin',
		title:'Fast Login',
		name:'fastLogin'
	},
	check:{
		url:'/auth/check',
		title:'Check',
		name:'check'
	},
	loginPhoneCode:{
		url:'/auth/loginPhoneCode',
		title:'Account Login Phone with Code',
		name:'loginPhoneCode'
	},
	loginPhone: {
		url: '/auth/loginPhone',
		title: 'Account Login Phone',
		name: 'loginPhone'
	},
	signUpPhone: {
		url: '/auth/signUpPhone',
		title: 'Account Sign Up With Phone',
		name: 'signUpPhone'
	},
	updatePassword: {
		url: '/auth/updatePassword',
		title: 'Password',
		name: 'updatePassword'
	},
	findPassword: {
		url: '/auth/findPassword',
		title: 'Retrieve Password',
		name: 'findPassword',
		subTitle: 'Enter the registered email address to start to retrieve the password'
	},
	loginWechat: {
		url: '/auth/login/wechat',
		title: 'Wechat Login',
		name: 'loginWechat'
	},
	resetPassword: {
		url: '/auth/resetPassword',
		title:'Reset Password',
		name:'resetPassword'
	},
	// login: {
	// 	url: '/auth/login/email',
	// 	title: 'Account Login',
	// 	name: 'login'
	// },
	// signupEmail: {
	// 	url: '/auth/signup/email',
	// 	title: 'Account Sign Up',
	// 	name: 'signupEmail'
	// },
	// updateUserinfo: {
	// 	url: '/auth/update/userinfo',
	// 	title: 'Setup Authorization Infomation',
	// 	name: 'updateUserinfo'
	// },
	// passwordReset: {
	// 	url: '/auth/password/reset',
	// 	title: 'Reset Password',
	// 	name: 'passwordReset',
	// 	icon: 'icon-aaIconChangeCode'
	// },

	// findEmail: {
	// 	url: '/auth/find/email',
	// 	title: 'Retrieve Email',
	// 	name: 'findEmail',
	// 	subTitle: 'Enter the registered mobile phone number to start to retrieve the mailbox ID'
	// },
	// bind: {
	// 	url: '/auth/bind',
	// 	title: 'Account Binding',
	// 	name: 'bind',
	// 	icon: 'icon-aaIconLink'
	// },
	userinfoPWReset: {
		url: '/auth/userinfo/password/reset',
		title: 'Reset Password',
		name: 'userinfoPWReset'
	},
	userinfoEmailVerify: {
		url: '/auth/userinfo/email/verify',
		title: 'Email Verify',
		name: 'userinfoEmailVerify'
	},
	userinfoBindWechat: {
		url: '/auth/userinfo/bind/wechat',
		title: 'Bind Wechat',
		name: 'userinfoBindWechat'
	},
	userinfoBindPhone: {
		url: '/auth/userinfo/bind/phone',
		title: 'Bind Phone',
		name: 'userinfoBindPhone'
	}
};
export const processRoute = {
	main: {
		url: '/process',
		title: 'Process',
		name: 'main'
	}
};
export const conditionRoute = {
	main: {
		url: '/condition',
		title: 'Condition',
		name: 'main'
	}
};
/* =====================================================================*/
export const memberRoute = {
	main: {
		url: '/member',
		path: '/member/:target?/:action?',
		name: 'member',
		title: 'Member'
	},
	//xinwang edit 20210218
	giftcard:{
		url:'/member/giftcard',
		name:'giftcard',
		title:'Member',

	}
};
export const discountRoute = {
	main: {
		url: '/discount',
		path: '/discount/:target?/:action?',
		name: 'discount',
		title: 'Discount'
	}
};
export const transportationRoute = {
	main: {
		url: '/transportation',
		path: '/transportation/:target?/:action?',
		name: 'transportation',
		title: 'Transportation'
	}
};
export const reportRoute = {
	main: {
		url: '/report',
		path: '/report/:target?/:action?',
		name: 'report',
		title: 'Report'
	}
};
export const restaurantRoute = {
	main: {
		url: '/restaurant',
		name: 'restaurant',
		title: 'Restaurant',
		path: '/restaurant/:target?/:action?'
	},
	detail: {
		url: '/restaurant/detail',
		name: 'restaurantDetail',
		title: 'Restaurant Detail'
	}
};
export const articleRoute = {
	main: {
		path: '/article/:target?/:id?',
		url: '/article',
		name: 'article',
		title: 'News',
		to:'/article'
	},
	list:{
		url:'/article',
		name:'list',
		title:'Articles'
	},
	detail: {
		path: '/article/detail/:id?',
		url: '/article/detail',
		name: 'detail',
		title: 'Article'
	}
};
export const transactionRoute = {
	main: {
		path: '/transaction/:target?/:method?',
		url: '/transaction',
		title: 'Transaction',
		name: 'main'
	},
	history: {
		url: '/transaction/history',
		title: 'Purchase History',
		name: 'history',
		icon: 'icon-aaIconPurchaseRecord'
	},
	payment: {
		url: '/transaction/payment',
		title: 'Payment',
		name: 'payment'
	},
	paymentWechat: {
		url: '/transaction/payment/wechat',
		title: 'Wechat Pay',
		name: 'wechat pay'
	},
	paymentStripe: {
		url: '/transaction/payment/stripe',
		title: 'Stripe Pay',
		name: 'Stripe pay'
	},
	dish: {
		url: '/transaction/dish',
		title: 'Dish',
		name: 'dish'
	},
	empty: {
		url: '/transaction/empty',
		title: 'No Data',
		name: 'empty'
	}
};
export const paymentRoute = {
	main: {
		url: '/payment',
		name: 'payment',
		title: 'Payment'
	}
};
export const managementRoute = {
	main: {
		path: '/management/:target?',
		url: '/management',
		name: 'main',
		title: 'Management'
	},
	// user: {
	// 	path: '/management/user/:target?',
	// 	url: '/management/user',
	// 	name: 'user',
	// 	title: 'User'
	// },
	profile: {
		url: '/management/profile',
		name: 'profile',
		title: 'My Profile'
	},
	history: {
		url:'/management/history',
		name:'history',
		title:'Purchase History'
	},
	updatePassword:{
		url:'/management/updatePassword',
		name:'updatePassword',
		title:'Password'
	},
	bind:{
		url:'/management/bind',
		name:'bind',
		title:'Account Binding'
	}
};
export const userRoute = {
	main: {
		url: '/user',
		name: 'user',
		title: 'User Center'
	},
	profile: {
		url: '/user/profile',
		name: 'profile',
		title: 'My Profile',
		icon: 'icon-Icon_Profile'
	},
	verification: {
		url: '/user/verification',
		name: 'verification',
		title: 'Go to Verify'
	},

	myDoctors: {
		url: '/user/myDoctors',
		name: 'myDoctors',
		title: 'My Doctors',
		icon: 'icon-Icon_Favorite'
	},
	myQas: {
		url: '/user/myQas',
		name: 'myQas',
		title: 'My Questions',
		icon: 'icon-Icon_Image_Post_2'
	},
	history: {
		url: '/user/history',
		name: 'history',
		title: 'Medical History',
		icon: 'icon-Icon_Browsing_History'
	},
	recharge: {
		url: '/user/recharge',
		name: 'recharge',
		title: 'Account Recharge'
	}
};
export const downloadRoute = {
	main: {
		url: '/download',
		name: 'download',
		title: 'Download APP'
	}
};
export const aboutRoute = {
	termsOfUse: {
		name: 'aboutTermsOfUse',
		url: '/About/termsOfUse',
		text: 'Terms of Use'
	},
	privacyPolicy: {
		name: 'aboutPrivacyPolicy',
		url: '/About/privacyPolicy',
		text: 'Privacy Policy'
	},
	refundPolicy: {
		name: 'aboutRefundPolicy',
		url: '/About/refundPolicy',
		text: 'Refund Policy'
	},
	helpCenter: {
		name: 'aboutHelpCenter',
		url: '/About/helpCenter',
		text: 'Help Center'
	},
	share: {
		name: 'aboutShare',
		url: '/About/share',
		text: 'Share'
	},
	contactUs: {
		name: 'aboutContactUs',
		url: '/About/contactUs',
		text: 'Contact Us'
	}
};

export const IOSDownloadLink = 'https://apps.apple.com/app/rorohome/id1511817790';
export const AndroidDownloadLink = 'https://play.google.com/store/apps/details?id=com.rorohome.android';
export const AndroidDownloadApkLink = 'https://roroshop.roro3.com/assets/app-release.apk';
