/*
 * @Author: your name
 * @Date: 2021-01-12 16:30:09
 * @LastEditTime: 2021-02-25 11:54:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \rorohomeadmin\client\src\contexts\Global\AuthContext.js
 */
import React, {createContext, useReducer, useEffect, useCallback, useContext, useMemo, useState} from 'react';
import axios from 'axios';
import getLanguage from '../../API/lib/getLanguage';
import localStorageSafe from '../../lib/localStorageSafe';
import GlobalContext from './GlobalContext';
import {authRoute, postRoute, transactionRoute, managementRoute, adminRoute, shopRoute} from '../../map/map';
import parseQuery from '../../lib/parseQuery';
import _ from 'lodash';
import ReactGA from 'react-ga';

import {
    checkavailabilityAPI,
    signUpPhoneAPI,
    loginWechatAPI,
    loginAPI,
    getProfileAPI,
    sendCodeAPI,
    postProfileAPI,
    postProfileImageAPI,
    userinfoPasswordChangeAPI,
    userinfoPasswordResetAPI,
    userinfoPasswordResetRequestByphoneAPI,
    postVerificationFiles,
    getHasNewMessage,
    userinfoBindWechatAPI,
    userinfoBindPhoneAPI,
    postHousingRequest, getUserAll,
    getGiftCard,
    activateGiftCard

} from '../../API/auth.api';
import { dispatchAction,dispatchLocalAction } from '../lib/index';

const Context = createContext({});

const initAuthState = {
    auth:{},
    user:{},
    accessToken:null,
    refreshToken:null,
    ...localStorageSafe.getItem('authState','object'),
    condition:{isLoading:false,error:{},isSuccess:false}
};
const initState = {
    item: {},
    condition: {}
};
const initItemsState = { items:[], categorys:[],condition:{ error:{} }, params:{} };
const initItemState = { item:{}, condition:{ error:{} }, params:{} };

const reducer = ( state,action={} )=>{
    const {actionType,payload={}, condition} = action;
    state.condition = condition;
    console.log('reducer',state,'action',action);
    switch(actionType){
        case 'getItems':
        case 'getItem':
            return {...state,...payload};
        case 'getMoreItems':
            return {...state,items:[...state.items,...payload.items]};
        case 'putItem':
            return { ...state, ...action, item: { ...state.item, ...action.item } };
        default:
            return {...state};
    }
};

const authReducer = (state={}, action={}) => {
    const {actionType,condition={}} = action;
    const {error={}} = condition;
    const payload = action.payload || {};
    console.log('authReducer',action);
    switch(actionType){
        case 'start':
            return{
                ...state,
                ...action
            };
        case 'fail':
            if (error.title) {
				window.pushError({ title: error.title, message: getLanguage() === 'zh' ? error.cnErrorMessage : error.enErrorMessage });
			}
			return {
				...state,
				...action
            };
        case 'checkAuth':
        case 'getHasNewMessage':
            return { ...state, ...action, ...payload };
        case 'getAuth':
        case 'getLogin':
        case 'getUser':
        case 'bindWechat':
        case 'bindPhone':
            localStorageSafe.setItem('authState', { ...state, actionType, ...payload });
            return { ...state, ...action, ...payload };
        case 'updatedPW':
            localStorageSafe.setItem('authState', { ...state, actionType, auth: { ...state.auth, passwordSet: true } });
            return { ...state, ...action, auth: { ...state.auth, passwordSet: true } };
        case 'getCode':
            return { ...state, ...action, code: payload.code };
        case 'clearAuth':
            localStorageSafe.setItem('prevAuthState',{...localStorageSafe.getItem('authState', 'object')});
            localStorage.removeItem('authState');
            return {    
                auth:{},
                user:{},
                accessToken:null,
                refreshToken:null,
                condition:{isLoading:false,error:{},isSuccess:false}
            };
        default:
            return { ...state };
    }
};

const resultHandler = ({actionType,payload}) => {
    switch (actionType){
		case 'checkAccount':
			if (payload.auth.userRegistered === true) {
                if(payload.auth.passwordSet === true){
                    window.pushG(`${authRoute.loginPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
                }else{
                    window.pushG(`${authRoute.loginPhoneCode.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
                }
			}else{
                window.alertG('Account Not Found',{icon:'icon-cross'})
            }
			// else if (payload.auth.userRegistered === false) {
			// 	window.pushG(`${authRoute.signUpPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
			// }
            break;
		case 'updateProfile':
		case 'updateProfileImage':
			// payload.user && window.alertG('Update Success',{icon:'ui icon check'})
            break;
        case 'fastLogin':
		case 'signUpPhone':
		case 'loginPhone':
		case 'loginWechat':
		case 'resetPasswordByCode':
		case 'updateProfileAddressLocation':
            localStorage.setItem('loginDate', new Date());
            console.log(payload)
            if(payload.auth && _.find(payload.auth.roles, role => role.name === 'ROLE_ADMIN')){
                // window.pushG(adminRoute.main.to);
                window.pushG(shopRoute.main.url);
            }else{
                window.pushError({title:'Error',message:'Only Admin Can Log In You Know. Ask Charlie For Account'});
                // window.pushG(shopRoute.main.url);
            }
			break;
		case 'updatePassword':
            if(payload.passwordChanged){
                ReactGA.event({
                    category:'Auth',
                    action:'Update password'
                })
                window.alertG('Update Success',{icon:'ui icon check'})
            }
                break;
		case 'resetPasswordByPhoneVerificationCode':
			window.pushG(authRoute.resetPassword.url + '?' + parseQuery.parseObjToQueryStr({ code:payload.code }))
            break;
        case 'bindWechat':
        case 'bindPhone':
            payload && payload.auth && window.alertG('Update Success',{icon:'ui icon check'})
            window.pushG(managementRoute.bind.url)
            break;
		case 'postHousingRequest':
            window.alertG('Update Success',{icon:'ui icon check'})
            break;
        default:
            break;
    }
}
export const Provider = props => {
    const {t} = useContext(GlobalContext);
    const [ state, dispatch ] = useReducer(authReducer, initAuthState);
    const [userState,userStateDispatch] = useReducer(reducer,initItemsState);
    const [itemState,itemDispatch] = useReducer(reducer,initItemState); //xinwang edit 20210218

    const {accessToken} = state;
    const [isServiceOpen,setIsServiceOpen] = useState(false);
    const [isServiceOpendetail,setIsServiceOpenDetail] = useState(false);
    const getUserAllAction = useCallback(dispatchAction(userStateDispatch,getUserAll,'getItems'));
    const getGiftCardAction = useCallback(dispatchAction(itemDispatch,getGiftCard,'getItem')); //xinwang edit 20210216
    const setActivateGiftCardAction = useCallback(dispatchAction(itemDispatch,activateGiftCard,'getItem')); //xinwang edit 20210216
    const checkAccountAction = useCallback(dispatchAction(dispatch, checkavailabilityAPI,'checkAuth'));
    const signUpPhoneAction = useCallback(dispatchAction(dispatch,signUpPhoneAPI,'getAuth'));
    const loginWechatAction = useCallback(dispatchAction(dispatch,loginWechatAPI,'getAuth'));
    const bindWechatAction = useCallback(dispatchAction(dispatch,userinfoBindWechatAPI,'bindWechat'));
    const bindPhoneAction = useCallback(dispatchAction(dispatch,userinfoBindPhoneAPI,'bindPhone'))
    const loginAction = useCallback(dispatchAction(dispatch,loginAPI,'getLogin','Login fail'));
    const getProfileAction = useCallback(dispatchAction(dispatch,getProfileAPI,'getUser'));
    const updateProfileAction = useCallback(dispatchAction(dispatch,postProfileAPI,'getUser'));
    const updateProfileImageAction = useCallback(dispatchAction(dispatch,postProfileImageAPI,'getUser'));
    const updatePasswordAction = useCallback(dispatchAction(dispatch,userinfoPasswordChangeAPI,'success'));
    const sendPhoneVerifyCodeAction = useCallback(dispatchAction(dispatch,sendCodeAPI,'success'));
    const resetPasswordByCodeAction = useCallback(dispatchAction(dispatch,userinfoPasswordResetAPI,'getAuth'));
    const findPasswordAction = useCallback(dispatchAction(dispatch,userinfoPasswordResetRequestByphoneAPI,'success'));
    const postVerificationFilesAction = useCallback(dispatchAction(dispatch,postVerificationFiles,'success'));
    const logoutAction = useCallback(dispatchLocalAction(dispatch,'clearAuth'));
    const getHasNewMessageAction = useCallback(dispatchAction(dispatch,getHasNewMessage,'getHasNewMessage'));
    const postHousingRequestAction = useCallback(dispatchAction(dispatch,postHousingRequest,'success'));
    // useEffect(()=>{
	// 	if (localStorage.getItem('loginDate') || (new Date(localStorage.getItem('loginDate')) < new Date('2020-03-20'))) {
	// 		localStorage.clear();
	// 	}
    // })
    const handleServiceModalToggle = useCallback(() => {
        setIsServiceOpen(value => !value);
    }, []);

    const _onChange = useCallback((e,data={}) => {
        console.log('_onChange',data);
        const { actionType, value = {},files, code, countryCode, verificationCode, phoneNumber, password, user={}, oldPassword, newPassword } = data;
        switch(actionType){
            case 'checkAccount':
                checkAccountAction({countryCode,phoneNumber}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'signUpPhone':
                signUpPhoneAction({countryCode,phoneNumber,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'loginPhone':
                loginAction({countryCode,phoneNumber,password,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'loginWechat':
                loginWechatAction({code}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'bindWechat':
                bindWechatAction({code}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'bindPhone':
                bindPhoneAction({countryCode,phoneNumber,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'getProfile':
                getProfileAction().then(payload=>{
                    console.log(payload)
                    resultHandler({actionType,payload})
                });
                break;
            case 'updateProfile':
                updateProfileAction({...user}).then(payload=>{
                    console.log('updateProfile',payload)
                    resultHandler({actionType,payload})
                });
                break;
            case 'updateProfileImage':
                console.log(files)
                updateProfileImageAction({files}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'updatePassword':
                updatePasswordAction({oldPassword,newPassword}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'sendPhoneVerifyCode':
                sendPhoneVerifyCodeAction({countryCode, phoneNumber}).then(payload => resultHandler({actionType,payload}));
                break;
            case 'resetPasswordByCode':
                resetPasswordByCodeAction({code,password:newPassword}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'fastLogin':
                dispatchLocalAction(dispatch,'getLogin')({...data.payload}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'logout':
                logoutAction().then(window.pushG(authRoute.main.to));
                break;
            case 'resetPasswordByPhoneVerificationCode':
                findPasswordAction({countryCode, phoneNumber, verificationCode}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'postVerification':
                const files = [];
				Object.entries(data.value).map(entry => {
					if (!_.isEmpty(entry[1])) {
						files.push({ file: entry[1][0].file, tags: `#${data.role}#${entry[0]}#0` });
					}
					return entry;
				});
                postVerificationFilesAction({imageFiles:files,role:data.role}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'checkHasNewMessage':
                localStorageSafe.getItem('authState', 'object').accessToken && getHasNewMessageAction()
                break;
            case 'postHousingRequest':
                postHousingRequestAction({...data.value}).then(payload=>resultHandler({actionType,payload}))
                break;
            //xin wang edit 20210216
            case 'getGiftCard':
                console.log('getGiftCard',value);
                getGiftCardAction(value).then((payload = {})=>{
                    
                    const { item } = payload;
                    console.log('getGiftCardAction payload',payload);
                    if (_.isEmpty(item)) {
                        setIsServiceOpen(true);
                        setIsServiceOpenDetail(false);
                    }else{
                        setIsServiceOpen(false);
                        setIsServiceOpenDetail(true);
                    }
                });
                break;
            //xin wang edit 20210216
            case 'setGiftCard':
                console.log('setgiftcard',value);
                setActivateGiftCardAction(value);
                break;
            //xin wang edit 20210216
            case 'setActivateGiftCard':
                console.log('setActivateGiftCard',value);
                getGiftCardAction(value).then((payload = {})=>{
                    
                    const { item } = payload;
                    console.log('getGiftCardAction payload',payload);
                    const {id} = {...item};
                    console.log('getGiftCardAction id',id);

                    if (id) {
                        setActivateGiftCardAction({id:id});
                    }
                })
                
                break;
            case 'getUser':
                console.log('getUser',value);
                
                getUserAllAction(value);
                break;
                      
            default:
                console.log(data);
                break;
        }
    },[]);
    const _onLoad = useCallback((data={}) => {
        const {loginWechat} = authRoute;
        const {actionType,code} = data;
        console.log('AuthContext',code);
        if(code){
            switch(actionType){
                case loginWechat.url:
                    loginWechatAction(data);
                    break;
                case 'checkAuthState':
                    const checkAuthState = {...localStorageSafe.getItem('authState','object'),condition:{isLoading:false,error:{},isSuccess:false}};
                    console.log(checkAuthState)
                    if (checkAuthState.accessToken){
                        dispatch({actionType:'getAuth',payload:{...checkAuthState}})
                    }
                    break;
                case 'member':
                    getUserAllAction(data);
                    console.log("onload setIsServiceOpen!!!!!!");
                    setIsServiceOpen(false);
                    setIsServiceOpenDetail(false);

                    break;
                default:
                    break;
            }
        }
    },[]);
    const genderOptions = useMemo(() => [ { key: 1, text: t('Male'), value: '1' }, { key: 2, text: t('Female'), value: '2' } ], [ t ]);
    console.log(state.condition)
    return <Context.Provider value={{ state,accessToken,onChange:_onChange, onLoad:_onLoad,genderOptions,serviceToggle:handleServiceModalToggle, isServiceOpen,setIsServiceOpen,isServiceOpendetail,setIsServiceOpenDetail,userState,itemState}}>{props.children}</Context.Provider>
}
export const withAuth = Component => props => {
    return(
        <Provider>
            <Component {...props}/>
        </Provider>
    );
};

export default Context;
