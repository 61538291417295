import _ from 'lodash';
export const SERVICE = 'service';
export const serviceControllerFieldObj = {
	getItems: { name: 'getItems', type: 'getItems', url: '/servicesShowPurchasing', method: 'get' },
	getItem: { name: 'getItem', type: 'getItem', url: '/serviceShow', method: 'get' }
};
export const serviceControllerFields = Object.values(serviceControllerFieldObj);
export const serviceReducer = (state, action = {}) => {
	switch (action.type) {
		case 'getItems':
			const getItems = _.map(action.items, item => {
				const strs = _.pickBy(_.split(item.name, '\n'), _.identity);
				console.log(strs);
				if (action.params.language === 'en') {
					item.title = strs[0];
				} else {
					item.title = strs[1];
				}
				return item;
			});
			return { ...state, ...action, items: getItems, isEmpty: _.isEmpty(action.items) && action.items ? true : false };
		case 'postItem':
			return { ...state, ...action, items: [ action.item, ...state.items ] };
		case 'getItem':
			console.log(action);
			const getItemStrs = _.pickBy(_.split(action._name, '\n'), _.identity);

			const getItem = { ...action, title: action.params.language === 'en' ? getItemStrs[0] : getItemStrs[1] };
			return { ...state, ...action, id: getItem.id, item: getItem, items: _.map(state.items, item => (item.id === getItem.id ? { ...item, ...getItem } : item)) };
		case 'putItem':
			return { ...state, ...action, items: _.map(state.items, item => (item.id === action.id ? action.item : item)) };
		case 'deleteItem':
			return { ...state, ...action, items: _.reject(state.items, item => item.id === action.id) };
		case 'getItemsLoad':
			return { ...state, ...action, items: [ ...state.items, action.items ] };
		case 'setItem':
			return { ...state, ...action, item: action.item, items: _.map(state.items, item => (item.id === action.item.id ? action.item : item)) };
		case 'success':
			return { ...state, ...action };
		case 'selectItem':
			return { ...state, ...action, item: _.find(state.items, item => item.id === action.id) || state.item };

		case 'init':
			return action.init;
		case 'start':
			return { ...state, ...action };
		case 'fail':
			return { ...state, ...action };
		default:
			return state;
	}
};
