import React, {createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {withRouter} from 'react-router-dom';
import GlobalContext from '../Global/GlobalContext';
import _ from 'lodash';
import {
	managementRoute,
	memberRoute,
	reportRoute,
	shopRoute,
	transactionRoute,
	transportationRoute
} from '../../map/map';
import {serviceReducer} from './serviceReducer';
import {
	getServiceItem,
	getTransaction,
	getTransactions, getTransportations,
	ordersShowMine,
	postCheckoutService,
	postItemService, postRefundCheckoutService, updateTransaction, updateTransactions
} from '../../API/transaction.api';
import {dispatchAction} from '../lib';
import PaymentContext from '../Payment/PaymentContext';
import AuthContext from '../Global/AuthContext';
import {getProduct} from '../../API/product.api';
import uuid from 'uuid';
import localStorageSafe from "../../lib/localStorageSafe";
import moment from "moment";

const Context = createContext({});
export const fieldObj = [
	{key:'V5A,V5B,V5C,V5E,V5G,V5H,V5J,V3N,V3E,V3J,V3K', value:100},
	{key:'V3E,V3J,V3K', value:100},
	{key:'V3A,V1M,V4W,V2Y,V2Z', value:120},
	{key:'V3L,V3M', value:120},
	{key:'V7L,V7M,V7P,V7G,V7H,V7J,V7K,V7N,V7R', value:0},
	{key:'V7A,V7B,V7C,V7E,V6V,V6W,V6X,V6Y', value:0},
	{key:'V4A,V4N,V4P,V3R,V3S,V3T,V3V,V3W,V3X,V3Z', value:120},
	{key:'V6A,V6B,V6C,V6E,V6G,V6H,V6J,V5K,V6K,V5L,V6L,V5M,V6M,V5N,V6N,V5P,V6P,V5R,V6R,V5S,V6S,V5T,V6T,V5V,V5W,V5X,V7X,V5Y,V7Y,V5Z,V6Z', value:0},
	{key:'V7S,V7T,V7V,V7W', value:0},
	{key:'V4B', value:120},
]
export const getTransportation = (transportation,price) =>{
	let temp = false;
	let value = 0;
	console.log('transportation',transportation,price);
	fieldObj.map((object,index)=>{
		value = object.value;
		if(object.key.includes(transportation.substring(0,3).toUpperCase())){
			temp = price > object.value;
		}
	})
	return {isTrue:temp,price:value};
};

const initState = {
	item: {},
	condition: {}
};
const initItemsState = { items:[], categorys:[],condition:{ error:{} }, params:{} };
const initItemState = { item:{}, condition:{ error:{} }, params:{} };

export const initBasicState = {
	name: null,
	condition: { isLoading: false, isSuccess: null, error: {} }
};
export const initRestfulState = {
	name: null,
	condition: { isLoading: false, isSuccess: null, error: {} },
	id: null,
	items: [],
	item: {}
};
const reducer = ( state,action={} )=>{
	const {actionType,payload={}, condition} = action;
	state.condition = condition;
	console.log('reducer',state,'action',action);
	switch(actionType){
		case 'getItems':
		case 'getItem':
			return {...state,...payload};
		case 'getMoreItems':
			return {...state,items:[...state.items,...payload.items]};
		case 'putItem':
			return { ...state, ...action, item: { ...state.item, ...action.item } };
		default:
			return {...state};
	}
};

const transactionReducer = (state, action = {}) => {
	const {actionType, payload={}} = action;
	console.log('transactionReducer',action);
	switch (actionType) {
		case 'postItemService':
			const postItemService = { ...payload.items, name: payload.items.contactName, phone: payload.items.contactPhone, transactionSerialId: payload.items.serialId, status: 'READY' };
			window.pushG(`/transaction/payment?transactionSerialId=${payload.items.serialId}`);
			let check = {...state,...action,item:postItemService};
			console.log('check',check);
			return check;
		case 'postItemDish':
			window.alertG('Success', { name: 'postItemDish', text: 'Order Success', icon: 'check' });
			return { ...state, ...action };
		case 'getItems':
			return { ...state, ...action, isEmpty: _.isEmpty(action.items) && action.items ? true : false };
		case 'getItem':
			return { ...state, ...action };
		case 'putItem':
			localStorageSafe.setItem('lineProductState', {...payload});
			console.log('test here putItem transactionReducer',localStorageSafe.getItem('lineProductState'));
			return state;
		case 'deleteItem':
			return { ...state, ...action, items: _.reject(state.items, item => item.id === action.id) };
		case 'getItemsLoad':
			return { ...state, ...action, items: [ ...state.items, action.items ] };
		case 'setItem':
			return { ...state, ...action, item: action.item, items: _.map(state.items, item => (item.id === action.item.id ? action.item : item)) };
		case 'selectItem':
			return { ...state, ...action, item: _.find(state.items, item => item.id === action.id) || state.item };
		case 'checkDish':
			return { ...state, ...action, amount: action.orderAmount };
		case 'checkAmount':
			return { ...state, ...action ,amount: { subTotal: payload.item.subTotal, pst: payload.item.pst, gst: payload.item.gst, transportationFee:payload.item.transportationFee,total: payload.item.total } };
		case 'success':
			return { ...state, ...action };
		case 'init':
			return action.init;
		case 'start':
			return { ...state, ...action };
		case 'fail':
			return { ...state, ...action };
		default:
			return state;
	}
};

export const Provider = withRouter(props => {
	const { match: { params: { target } }, history: { push } } = props;
	const [ state, dispatch] = useReducer(transactionReducer,{...initState, amount: {} });
	const { onChange } = useContext(PaymentContext);
	const [itemsState,itemsStateDisPatch] = useReducer(reducer,{...initItemsState});
	const [reportState,reportStateDisPatch] = useReducer(reducer,{...initItemsState});
	const {state:{user={}}} = useContext(AuthContext);
	const {t} = useContext(GlobalContext);
	const [startDate,setStartDate] = useState(moment().subtract(1,'month'));
	const [endDate,setEndDate] = useState(moment());
	//ItemState
	const [itemState,itemStateDisPatch] = useReducer(reducer,{...initItemState});
	const [transaction,setTransaction] = useState({});
	const getItemAction = useCallback(dispatchAction(itemStateDisPatch,getProduct,'getItem'));
	const [ sectionIndex, setIndex ] = useState(0);
	const [ serviceState,serviceDispatch] = useReducer(serviceReducer,{...initState});
	const [ transportation,setTransportation] = useState({});
	const { language,query } = useContext(GlobalContext);
	const { items: serviceItems} = itemsState;
	const [ isServiceOpen, setIsServiceOpen ] = useState(false);
	const [ isRefundOpen, setIsRefundOpen ] = useState(false);
	const [ isNoteOpen, setNoteOpen] = useState(false);
	const [ isLpOpen, setIsLpOpen ] = useState(false);
	const [transactionReport, setTransactionReport] = useState(new Set());
	//for toggles
	const [isSelected,setSelected] = useState(false);
	let selectItemList = [];
	const [ refund, setRefund ] = useState({});
	const [filter, setFilter] = useState({});
	const [ error, setError ] = useState({});
	const [ sessionId, setSessionId ] = useState(uuid.v4());
	const getTransactionAction = useCallback(dispatchAction(itemStateDisPatch,getTransaction,'getItem'),[]);
	const getMoreTransactionsAction = useCallback(dispatchAction(itemsStateDisPatch,getTransactions,'getMoreItems'),[]);
	const getTransactionsAction = useCallback(dispatchAction(itemsStateDisPatch,getTransactions,'getItems'),[]);
	const getTransactionsReportAction = useCallback(dispatchAction(reportStateDisPatch,getTransactions,'getItems'),[]);
	const getTransportationAction = useCallback(dispatchAction(itemsStateDisPatch,getTransportations,'getItems'),[]);
	const getServiceItemAction = useCallback(dispatchAction(serviceDispatch,getServiceItem,'getItem'),[]);
	const postServiceAction = useCallback(dispatchAction(dispatch,postItemService,'postItemService','Transaction Create Fail,Please Try Again or Contact Us'),[]);
	const calculatePriceAction = useCallback(dispatchAction(dispatch,postCheckoutService,'checkAmount'),[]);
	const showMineAction = useCallback(dispatchAction(itemStateDisPatch,ordersShowMine,'getItems'),[]);
	const postRefundAction = useCallback(dispatchAction(itemStateDisPatch,postRefundCheckoutService,'getItems'),[]);
	const updateTransactionAction = useCallback(dispatchAction(itemStateDisPatch,updateTransaction,'getItem'),[])
	const updateTransactionsAction = useCallback(dispatchAction(itemsStateDisPatch,updateTransactions,'getItems'),[])
	const handleNoteOpenToggle = useCallback(() => {
		setNoteOpen(value => !value);
	}, []);
	const handleTransactionSelectToggle = useCallback(() => {
		setSelected(value => !value);
	}, []);
	const handleServiceModalToggle = useCallback(() => {
		setIsServiceOpen(value => !value);
	}, []);
	const handleRefundModalToggle = useCallback(() => {
		setIsRefundOpen(value => !value);
	}, []);
	const validNextStep = useMemo(
		() => {
			return {
				0: refund.price > 0,
				1:!(_.isEmpty(refund.description) || _.isEmpty(refund.price)),
			};
		},
		[ state,refund]
	);
	const reportData = useCallback((items)=>{
		let dishes = [];
		items.filter((item) => item.paymentStatus == 1).map((item,key)=> {
			let ms = Date.parse(item.createdAt);
			let startMs = startDate.toDate().getTime();
			let endMs = endDate.toDate().getTime()
			if(ms > startMs && ms < endMs){
				const {dishOrders} = item;
				dishOrders.map((item,key) =>{
					const {dish,quantity,subtotal} = item;
					const set = {...dish,quantity,subtotal};
					dishes.push(set);
				});
			}
		});
		let dishSet = Array.from(new Set(dishes.map((dish=>dish.id)).map(id=>{
			return dishes.find(d => d.id === id);
		})));
		let dishesTotal = [];
		for(let i=0;i<dishSet.length;i++){
			let quantity = 0;
			let subtotal = 0;
			for(let j=0;j<dishes.length;j++){
				if(dishes[j].id == dishSet[i].id){
					quantity = quantity + dishes[j].quantity;
					subtotal = subtotal + dishes[j].subtotal;
				}
			}
			dishesTotal.push({...dishSet[i],quantity,subtotal});
		}
		setTransactionReport(dishesTotal);
	},[transactionReport,startDate,endDate]);
	const _onLoad = useCallback(
		action => {
			const {pathname, query, match,search} = action;
			console.log('_onLoad action pathname',action);
			if(pathname === managementRoute.history.url){
				showMineAction({params: {language}})
			}else if(pathname === reportRoute.main.url) {
				getTransactionsReportAction({page:0,size:49,paymentStatus:1,type:'dish',sort:'createdAt,desc'}).then(res => {
					if(res){
						reportData(res.items);
					}
				});
			}else if(pathname === transportationRoute.main.url){
				getTransportationAction({params:{}});
			}else{
				getTransactionsAction({page:0,size:2000,type:'dish',sort:'createdAt,desc'});
			}
		},[language,query]);
    const _onChange = useCallback((e,data) =>{
		console.log('_onChange',data);
		const {name , value={},id,payload} = data;
		switch(name){
			case 'setSelect':
				const {serialId:transactionId} = payload;
				if(selectItemList.includes(transactionId)){
					selectItemList = selectItemList.filter((item)=> item != transactionId.toString());
				}else{
					selectItemList.push(transactionId);
				}
				if(selectItemList.length > 0){
					setSelected(true);
				}else{
					setSelected(false);
				}
				break;
			case 'cancelSelect':
				setSelected(false);
				selectItemList = [];
				break;
			case 'multipleUpdate':
				console.log('multipleUpdate',selectItemList,'payload',payload);
				const transactionIds = selectItemList;
				console.log('multipleUpdate transactionIds',transactionIds.toString());
				updateTransactionsAction({transactionIds,payload}).then((data)=>{
					getTransactionsAction({page:0,size:2000,type:'dish',sort:'createdAt,desc'});
				});
				break;
			case 'sectionBack':
				setIndex(data.value -1);
				break;
			case 'getServiceItem':
				getServiceItemAction({ params: { id: data.value, language: language } })
				handleServiceModalToggle();
				break;
			case 'getItem':
				setTransaction(data.item);
				handleServiceModalToggle();
				break;
			case 'checkQuantity':
				if(data.value.quantity === 0){
					const message = 'Please Select Quantity';
					window.pushError({title:t('Quantity Cannot Be Zero'),message:t(message)})
				}else{
					handleServiceModalToggle();
				}
				break;
			case 'getMoreItems':
				getMoreTransactionsAction({paymentStatus:1,sort:'createdAt,desc',page:data.page,size:10,type:'dish'});//xinwang edit 2021/09/23
				break;
			case 'putItem':
				console.log('putItem',value);
				break;
			case 'deleteItem':
				break;
			case 'paymentWechat':
				console.log('paymentWechat', data.value);
				window.pushG(`/transaction/payment/wechat?transactionSerialId=${data.value}`);
				break;
			case 'paymentStripe':
				window.pushG(`/transaction/payment/stripe?transactionSerialId=${data.value}`);
				break;
			case 'getTransaction':
				console.log('getTransaction',data.value);
				getTransactionAction(data.value)
				break;
			case 'setShipping':
				setTransportation(data.value);
				break;
			case 'setRefund':
				console.log('setRefund', data);
				setRefund(data.amount.price);
				break;
			case 'putTransaction':
				console.log('putTransaction',data);
				const {value,serialId} = payload
				const restTransaction = {deliveryStatus:value};
				const params = {restTransaction,serialId}
				updateTransactionAction(params).then((data)=>{
					setTransaction(data.item[0]);
					getTransactionsAction({page:0,size:2000,type:'dish',sort:'createdAt,desc'});
				});
				break;
			case 'reportData':
				const {items} = reportState;
				reportData(items)
				break;
			default:
				console.log('not set');
				break;
		}
	},[transactionReport]);
	const _onSubmit = useCallback((e,action) =>{
		const {name, payload={}} = action;
		console.log('onSubmit',action);
		switch(name){
			case 'lineItem':
				setIndex(1);
				break;
			case 'refundCheckout':
				console.log('refundCheckout',{refund:{transactionSerialId:payload.transaction.serialId,description:payload.description,amount:Number(payload.price)}});
				postRefundAction({refund:{transactionSerialId:payload.transaction.serialId,description:payload.description,amount:Number(payload.price)}});
				break;
			case 'postCheckoutShipping':
				break;
			case 'postItemService':
				break;
			case 'setRefund':
				console.log('setRefund', action);
				setRefund(action.amount.price);
				break;
			case 'setTransactionNotes':
				const {serialId} = payload;
				const restTransaction = {notes:payload.notes};
				const params = {restTransaction,serialId}
				updateTransactionAction(params).then((data)=>{
					handleNoteOpenToggle();
					setTransaction(data.item[0]);
					getTransactionsAction({page:0,size:2000,type:'dish',sort:'createdAt,desc'});
				});
				break;
			default:
				console.log('not set');
				break;
		}
	},[transaction]);
	console.log('TransactionContext',selectItemList);
	return(
		<Context.Provider
		value={{
			state,
			itemsState,
			sectionIndex,
			setSessionId,
			setIndex,
			isLpOpen,
			isServiceOpen,
			isRefundOpen,
			setRefund,
			onServiceToggle: handleServiceModalToggle,
			onRefundToggle:handleRefundModalToggle,
			onSelectToggle:handleTransactionSelectToggle,
			onNoteToggle:handleNoteOpenToggle,
			onChange: _onChange,
			onLoad: _onLoad,
			onSubmit: _onSubmit,
			sessionId,
			itemState,
			transaction,
			isSelected,
			selectItemList,
			validNextStep,
			transactionReport,
			isNoteOpen,
			startDate,
			setStartDate,
			endDate,
			setEndDate,
			reportData,
			refund
		}}>
		{props.children}
	</Context.Provider>
	)
})
export const validate = (fields=[]) => values => {
	const errors ={};
	fields.map(field =>{
		if(field.required && !values[field.name]){
			errors[field.name] = 'Required';
		}
		if(!values[field.name]){
		} else if((field.pattern && !field.pattern.test(values[field.name]))){
			errors[field.name] = 'Please enter valid value';
		}
		return field;
	});
	return errors;
}
export const withTransaction = Component => props => {
	return (
		<Provider>
			<Component {...props} />
		</Provider>
	);
};
export default Context;
