import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
	authRoute,
	discountRoute,
	managementRoute,
	memberRoute,
	productRoute, reportRoute,
	shopRoute,
	transactionRoute, transportationRoute
} from '../map/map';
import RouteBasic from './RouteBasic';
import RouteUser from './RouteUser';
import RouteAdmin from './RouteAdmin';
const ProductCategoryNav = React.lazy(() => import('../components/Product/ProductCategoryNav/ProductCategoryNav'))
const HomePage = React.lazy(() => import('../components/Pages/HomePage/HomePage'));
const AuthPage = React.lazy(() => import('../components/Pages/AuthPage/AuthPage'));
const ProductPage = React.lazy(() => import('../components/Pages/ProductPage/ProductPage'))
const TransactionPage = React.lazy(() => import('../components/Pages/TransactionPage/TransactionPage'));
const ManagementPage = React.lazy(() => import('../components/Pages/ManagementPage/ManagementPage'));
const ShopPage = React.lazy(() => import('../components/Pages/ShopPage/ShopPage'));
const MemberPage = React.lazy(() => import('../components/Pages/MemberPage/MemberPage'));
const DiscountPage = React.lazy(() => import('../components/Pages/DiscountPage/DiscountPage'));
const ReportPage = React.lazy(()=> import('../components/Pages/ReportPage/ReportPage'));
const TransportationPage = React.lazy(()=> import('../components/Pages/TransportationPage/TransportationPage'));

const NoMatchPage = () => {
	return (
		<h3>404 - Not found</h3>
	);
};


const Routes = () => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				{/*<RouteBasic exact {...managementRoute.main} component={ManagementPage} />*/}
				<RouteBasic exact {...authRoute.main} component={AuthPage} />
				<RouteBasic exact {...shopRoute.main} component={ShopPage} />
				<RouteBasic exact {...productRoute.main} component={ProductPage} />
				<RouteBasic exact {...memberRoute.main} component={MemberPage} />
				<RouteBasic exact {...discountRoute.main} component={DiscountPage}/>
				<RouteBasic exact {...reportRoute.main} component={ReportPage}/>
				<RouteBasic exact {...transportationRoute.main} component={TransportationPage}/>
				{/*<RouteBasic exact {...transactionRoute.main} component={TransactionPage} />*/}
				<Route exact path='/' component={HomePage} />
				<Route component={NoMatchPage} />
			</Switch>
		</Suspense>
	);
};
export default Routes;
