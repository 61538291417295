import _ from 'lodash';

export const commaStringToObjList = (inputString,length,keyNames=[])=>{
    const list = [];
    _.chunk(_.compact(_.split(inputString,/,|，/)),length).map((lineValue)=>{        
        const object = {}
        lineValue.map((value,index)=>{
            object[keyNames[index]]=value
            return value
        })
        list.push(object)
        return list
    })
    return list;
}
export const objListToCommaString = (inputList)=>{
    let string = '';
    _.forEach(inputList,(lineValue)=>{
        _.forEach(lineValue,(value,key)=>{
            string+=value+','
        })
    })
    return string.slice(0,-1)
}