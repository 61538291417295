import axios from 'axios';
import { name } from 'faker';
import autharizedHeaders from './lib/autharizedHeaders';


const postAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API });
const productAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API+'/dish' });
const labelAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API+'/label' });
const imageAPI = axios.create({ baseURL: process.env.REACT_APP_UPLOAD_DATA_API +'uploadedFile' });
const imagePostAPI = axios.create({ baseURL: process.env.REACT_APP_UPLOAD_DATA_API +'api/fileUpload/' });

// const tempHeaders = () => {
//     return { Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDUzOTYzOTgsInVzZXJfbmFtZSI6IjEtMTIzNDU2NzgwMDYiLCJhdXRob3JpdGllcyI6WyJBRE1JTl9PUEVSQVRJT05TIiwiQVJUSUNMRV9XUklURSIsIkNSQVdMRVJfREFUQV9XUklURSIsIlBPU1RfTUFOQUdFIiwiUkVTVEFVUkFOVF9XUklURSIsIkNSQVdMRVJfREFUQV9SRUFEIiwiU1VQRVJBRE1JTl9PUEVSQVRJT05TIiwiU0VSVklDRV9NQU5JUFVMQVRFIl0sImp0aSI6ImNmMWFkMWFlLWQxNTMtNDdkYy04YzNlLWQwOWM2NTk3NmRjYyIsImNsaWVudF9pZCI6Imxvbmd0ZXJtX2NsaWVudCIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdfQ.qw6HztnTkc5mIvu4-28xtEBA1jSR5CCHrpCYJ6msRYJqdK-lPZv4V_QsY1DFKTB9zpX_kvnPV-DSyzWuIbZMOHMPBv9BoPJZTryceacvkROYJi9DQJBiseyRtWMXPZOmUCc0HwJtFeTuIkPOO3lH3-IBz9rY1oyvQwG3YmA_tohmSvUeYX0WKb42eUx1zpgii9fupNXh-Tw2RAMRWsgPiHbBYy0M_Yj80KWHSK-vOZeKwTUHJ4rTNFG86I7uy_VV25yn4XKZ-f06nh29M0rJamDMg2C2Q19TcJojuXwTZ8dhz9KUFb8_zg-PfZ_rxuRu04ASgRX6rPlKypHsqf15UQ` , 'Content-Type': 'application/json' };
// };
export const getProducts = async({params})=>{
    console.log('getProducts',params);
    const {name,labelId} = params;
    //let _params = { sort:'createdAt,desc', size:1000,page:0,restaurantId:411,is_recommended:true};
    let _params = { sort:'updatedAt,desc', size:100000000,page:0,name:name,labelId:labelId};
    return await productAPI.get('/',{params:_params}).then(res=>({
        items:res.data.dishs,
        categorys:Array.from(new Set(res.data.dishs.map(dish=>dish.category)))
    }))
}

export const getLabeledProducts = async(payload)=>{
    console.log('getLabeledProducts',payload);
    const {id} = payload;
    let _params = { sort:'updatedAt,asc', size:1500000,page:0,labelId:id};
    return await productAPI.get('/',{headers: autharizedHeaders(),params:_params}).then(res=>({
        items:res.data.dishs
    }))
}

export const getMoreProducts = async (payload)=>{
    console.log('getMoreProducts',payload);
    // let _params = { sort:'createdAt,desc', size:1000,page:0,restaurantId:411,is_recommended:true};
    let _params = { sort:'updatedAt,desc', size:1500000,page:payload};
    return await productAPI.get('/',{params:_params}).then(res=>({
        items:res.data.dishs,
        categorys:Array.from(new Set(res.data.dishs.map(dish=>dish.category)))
    }))
}
export const getProductsWithCategory = async({params})=>{
    let _params = { sort:'updatedAt,desc', size:1500000,page:0,...params,restaurantId:411,is_recommended:true};
    return await productAPI.get('/',{params:_params}).then(res=>({
        items:res.data.dishs,
        categorys:Array.from(new Set(res.data.dishs.map(dish=>dish.category)))
    }))
}
export const getProduct = async({params})=>{
    const {id} = params;
    const fetchedProduct = await productAPI.get(`/${id}`).then(res=>({
        item:res.data.dish,params:{id}
    }))
    if(!fetchedProduct.item){
        throw new Error('Product not found, id: '+ id);
    } else {
        return fetchedProduct;
    }
};

export const uploadImages = async ({ imageFiles = [],uploadParams:{withoutWaterMark} }) => {
    const formData = new FormData();
    imageFiles.forEach(({ file, tags }) => {
        formData.append('images', file);
        formData.append('tagsList', tags);
    });
    formData.append('withoutWaterMark',withoutWaterMark);
    return await postAPI.post('/uploadImages', formData, { headers: { ...autharizedHeaders(), 'Content-Type': 'multipart/mixed' } }).then(res => res.data.images);
};

// export const postProduct = async (payload) => {
//     console.log('post productAPI',payload)
//     const { imageFiles = [], images,label, ...rest } = payload;
//     const uploadedImages = await uploadImages({ imageFiles, uploadParams: { withoutWaterMark: true } });
//     const image = uploadedImages[0];
//     const labelIds = await getParentLabels(label);
//     console.log('productAPI',labelIds);
//     console.log('productAPI',{dish:{...rest, image: {image}}});
//     return await productAPI.post('/',{dish:{...rest}},{headers: autharizedHeaders()}).then(res => ({
//         item:res.data.dish
//     }))
// }

//xinwang edit 20210203 create product
export const postProduct = async (payload) => {
    console.log('post productAPI',payload);
    const {category,bestBefore,price,restaurantId,discountedPriceDouble,cost,labels,name, isRecommended,quantity,bindUnit,isBestDeal,availability,visibility,description,excerpt,iconimageUrl} = payload;
    let editPriceDouble = parseFloat(price);
    let editDiscountedPriceDouble = parseFloat(discountedPriceDouble);
    let editCost = parseFloat(cost);
    delete payload.price;
    delete payload.labels;
    let labelIds = [];
    let dish;

    if(labels != undefined){
        for(let i in labels){                    
            labelIds.push(labels[i].id);
        }
    
        if(labelIds.indexOf(1) == '-1'){
            labelIds.push(1);
    
        }//xinwang edit 20210208
        
        dish = {
                name: name,
                restaurantId : restaurantId,
                priceDouble : editPriceDouble,
                isRecommended : isRecommended,
                bestBefore:bestBefore,
                unitType: null,
                cost:editCost,
                quantity:quantity,
                discountedPriceDouble:editDiscountedPriceDouble,
                bindUnit: bindUnit,
                isBestDeal : isBestDeal,
                availability : availability,
                labelIds:labelIds,
                visibility : visibility ,
                description : description,
                excerpt: excerpt,
                category: category,
                image: {
                    imageUrl: iconimageUrl ,
                    thumbnailUrl : iconimageUrl,
                    tags : "#roroHome#shopify"
                }
        
        };

    }else{

        dish = {    
            name: name,
            restaurantId : restaurantId,
            priceDouble : editPriceDouble,
            isRecommended : isRecommended,
            bestBefore:bestBefore,
            unitType: null,
            cost:editCost,
            quantity:quantity,
            discountedPriceDouble:editDiscountedPriceDouble,
            bindUnit: bindUnit,
            isBestDeal : isBestDeal,
            availability : availability,
            visibility : visibility ,
            description : description,
            excerpt: excerpt,
            category: category,
            image: {
                imageUrl: iconimageUrl ,
                thumbnailUrl : iconimageUrl,
                tags : "#roroHome#shopify"
            }
    
        };
    }
    console.log('post productAPI dish',dish);

    return await productAPI.post('/',{dish},{headers: autharizedHeaders()}).then(res => ({
        item:res.data.dish
    }))
}
// edit product one property
export const putProductQuick = async (payload) => {
    console.log('putProductQuickAPI',payload)
    const { id,...rest } = payload;

    return await productAPI.put(`/${id}`,{dish:{...rest}},{headers: autharizedHeaders()}).then(res => ({
        item:res.data.dish
    }))
}

// export const putProduct = async (payload) => {
//     console.log('put productAPI',payload)
//     const { imageFiles = [], images,id, ...rest } = payload;
//     const uploadedImages = await uploadImages({ imageFiles, uploadParams: { withoutWaterMark: true } });
//     const image = uploadedImages[0];
//     // const upload = {imageUrl:image.fileUrl,thumbnailUrl:image.thumbnailUrl,tags:image.tags};
//     // console.log('productAPI',{dish:{...rest, image: {imageUrl:image.fileUrl,thumbnailUrl:image.thumbnailUrl,tags:image.tags}}})
//     // return await productAPI.put(`/${id}`,{dish:{visibility}},{headers: autharizedHeaders()}).then(res => ({
//     return await productAPI.put(`/${id}`,{dish:{...rest,image:image}},{headers: autharizedHeaders()}).then(res => ({
//         item:res.data.dish
//     }))
// }


// edit product 
export const putProduct = async (payload) => {
    console.log('putproductAPI',payload)
    const {availability,quantity,iconimageUrl,id,...rest } = payload;
    let image = {        
        imageUrl: iconimageUrl,
        thumbnailUrl: iconimageUrl,
        tags: "#roroHome#shopify"
    };

    let _quantity = quantity;
    if(quantity == 0){
        if (availability != null){
            if(availability === true){
                _quantity = 99;
            }else if(availability === false){
                _quantity = 0;
            }
        }

    }
    return await productAPI.put(`/${id}`,{dish:{...rest,quantity:_quantity,image:image}},{headers: autharizedHeaders()}).then(res => ({
        item:res.data.dish
    }))
}

//Create Product Label
export const postProductLabel = async(payload)=>{
   
    const {visibility, cnName,enName,labels,title,iconimageUrl,coverimageUrl} = payload;
    const {id,level} = labels;// 父标签
    let label = {
        title:title,
        level:level+1,
        cnName:cnName,
        enName:enName,
        parentId:id,
        visibility:visibility,
        iconImage:{
            imageUrl: iconimageUrl,
            thumbnailUrl: iconimageUrl,
            tags: "#rorohome"
        },
        
        coverImage:{
            imageUrl: coverimageUrl,
            thumbnailUrl: coverimageUrl,
            tags: "#rorohome"
        }   

    };
    return await labelAPI.post('/',{label},{headers: autharizedHeaders()}).then(res=>({
        item:res.data.label,
    }))
};


//edit Product Label
export const putProductLabel = async(payload)=>{
    console.log('putProductLabel',payload);
    const {visibility, cnName,enName,labels,title,iconimageUrl,coverimageUrl,id:itemId} = payload;
    let label
    if(labels){
        const {id,level} = labels;
        label = {
            title:title,
            level:level+1,
            cnName:cnName,
            enName:enName,
            parentId:id,
            visibility:visibility,
            iconImage:{
                imageUrl: iconimageUrl,
                thumbnailUrl: iconimageUrl,
                tags: "#rorohome"
            },
            
            coverImage:{
                imageUrl: coverimageUrl,
                thumbnailUrl: coverimageUrl,
                tags: "#rorohome"
            }   
        };
    }else{
        label = {
            title:title,
            cnName:cnName,
            enName:enName,
            visibility:visibility,
            iconImage:{
                imageUrl: iconimageUrl,
                thumbnailUrl: iconimageUrl,
                tags: "#rorohome"
            },
            
            coverImage:{
                imageUrl: coverimageUrl,
                thumbnailUrl: coverimageUrl,
                tags: "#rorohome"
            } 

        };
    }
    console.log('putProductLabel',label,itemId);
    return await labelAPI.put(`/${itemId}`,{label},{headers: autharizedHeaders()}).then(res=>({
        item:res.data.label,
    }))
};

//edit Product Label Visibility
export const putQuickProductLabel = async(payload)=>{
    console.log('putQuickProductLabel',payload);
    const {visibility, id} = payload;
    let label = {visibility};
    return await labelAPI.put(`/${id}`,{label},{headers: autharizedHeaders()}).then(res=>({
        item:res.data.label,
    }))
};

export const getProductLabels = async({params})=>{
    console.log('getLabels',params);
    let _params = { sort:'updatedAt,desc', size:100000000,page:0};
    return await labelAPI.get('/',{headers: autharizedHeaders(),params:_params}).then(res=>({
        items:res.data.labels,
    }))
};

export const getProductLabel = async(payload)=>{
    console.log('getLabel',payload);
    const {id} = payload;
    return await labelAPI.get(`${id}` ,{headers: autharizedHeaders()}).then(res=>({
        item:res.data.label,
    }))
};

export const getParentLabels = async (payload) =>{
    console.log('getParentLabels',payload);
    let {parentId,id} = payload;
    let arr = [];
    arr.push(id);
    while(id != undefined){
        let parentId = await labelAPI.get(`${id}` ,{headers: autharizedHeaders()}).then(res=>{
            let data = res.data.label || {};
            const {parentId} = data;
            if(parentId != undefined || parentId != null){
                arr.push(parentId);
                return parentId;
            }
        })
        id = parentId;
    }
    return arr;
};

export const getProductLabelParents = async(payload)=>{
    console.log('getLabel',payload);
    const {id} = payload;
    return await labelAPI.get(`${id}` ,{headers: autharizedHeaders()}).then(res=>({
        item:res.data.label,
    }))
};

export const getUploadedFiles = async (payload) =>{
    console.log('getUploadedFiles',payload);
    let _params = { size:'9999', sort:'updatedAt,desc'}; //xinwang edit 20210324
    return await imageAPI.get(`/` ,{params:_params}).then(res=>({
        item:res.data.uploadedFiles,
    }))
};

export const productUploadImages = async ({ imageFiles = []}) => {
    console.log('productUploadImage',imageFiles);
    const formData = new FormData();
    let symbol = '$rorohome$';
    imageFiles.forEach(({ file, tags }) => {
        var newFileName = symbol+file.name;
        formData.append('files', file,newFileName);
    });
    return await imagePostAPI.post('/uploadMultipleFiles', formData, { 'Content-Type': 'multipart/mixed' } ).then(res => res.data.fileUrls);
};

export const putProductImage = async (payload) => {
    const {name,days} = payload
    return await imageAPI.put(`list/${name}`,{lastDays:days}).then(res=>res.data.uploadedFiles);
};
