import axios from 'axios';
import platform from './lib/platform';
import autharizedHeaders from './lib/autharizedHeaders';

const alipayForm = axios.create({ baseURL: 'http://172.16.0.34:48080/' });
const paymentAPI = axios.create({ baseURL: process.env.REACT_APP_PAYMENT_API});

export const alipayFormAPI = async () => {
	return await alipayForm.get(platform.isMobile() ? `/wappay` : `/webpay`, {}).then(res => {
		return { __html: res.data.form };
	});
};

export const postStripe = async (payload) => {
	console.log('postStripe',payload);
	const {token,transactionSerialId} = payload;
	return await paymentAPI.post(`/transactions/${transactionSerialId}/pay/stripe`,{stripeToken:token.id},{ headers: autharizedHeaders() }).then(res => {
		return res.data
	});
}

export const postWechat = async (payload) => {
	const {transactionSerialId} = payload;
	return await paymentAPI.post(`/transactions/${transactionSerialId}/pay/wechat`,{tradeType:'NATIVE'},{headers:autharizedHeaders()}).then(res => {
		return res.data
	})
}